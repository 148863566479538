import React from 'react';
import { Link } from 'gatsby';
import Image from "../components/image";
import "typeface-montserrat-subrayada";

const HomePage = (props: any) => {
  const { data } = props;


  return (
    <div>
 {/* <div style={{backgroundImage:"url('https://source.unsplash.com/featured/2000x2000/?globe')",height:"1000px",width:"100%"}}> */}
      <div>
        <div style={{position:"relative",height:"100%",zIndex:1}}>
          <Image filename="top.jpg"></Image>
        </div>
        <div>
          <div style={{position:"absolute",margin:"-50% 0 0 10vw"}}>
            <h1 style={{position:"absolute",fontSize:"3rem",width:"80vw",color:"white", textShadow:"20px",zIndex:5,fontFamily: "montserrat-subrayada"}}>Social Area Analyze System<br/>by 帝国書院</h1>
          </div>
        </div>
        <div>
          <div style={{position:"absolute",margin:"-30% 0 0 10vw",color:"blue"}}>
            <Link to="/themeset" style={{position:"absolute",fontSize:"3rem",width:"80vw",color:"white", textShadow:"20px",zIndex:5}}>SAASを使う</Link>
          </div>
          {/* <div>
            <br/>
            <br/>
            <br/>
            <div style={{backgroundColor:"darkblue", color:"white", width:"30%", height:"3rem", fontSize:"2rem", textAlign:"center",margin:"1rem",zIndex:5}}>
              <Link to="/themeset" style={{color:"white", width:"8rem", height:"3rem", fontSize:"2rem",margin:"0.5rem",zIndex:5}}>SAASを使う</Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomePage;

